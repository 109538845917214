import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { Toolbar } from 'src/components';
import { RoutingContainer, RoutingToolbarContainer, RoutingPageContainer } from './styles';
import { Landing, Leaderboard, Testing, UnknownRoute } from '..';

const DefaultLogoUrl =
  'https://minio-firstlink.osinit.com/osinit/253b6b48-374d-48cb-8629-8b6bc59afc9c/Theme/c90e978f-2dc0-4fa5-a254-567265345cf2_logo.svg';
const DefaultSiteUrl = 'https://osinit.com/';

type RoutingContextType = {
  setCompany?: (companyLogoUrl?: string, companySiteUrl?: string) => void;
};

export const RoutingContext = React.createContext<RoutingContextType>({});

/*
  Функция для определения высоты экрана
  с учетом нижней панели
 */
function setHeight() {
  const vh = window.innerHeight * 0.01;

  document.getElementById('routing-container').style.setProperty('--vh', `${vh}px`);
}

export const Routing: React.FC = () => {
  const [companyLogoUrl, setCompanyLogoUrl] = React.useState<string>(DefaultLogoUrl);
  const [companySiteUrl, setCompanySiteUrl] = React.useState<string>(DefaultSiteUrl);

  const setCompany = (companyLogoUrl?: string, companySiteUrl?: string): void => {
    setCompanyLogoUrl(companyLogoUrl);
    setCompanySiteUrl(companySiteUrl);
  };

  React.useEffect(() => {
    window.addEventListener('resize', setHeight);
    setHeight();
    return () => window.removeEventListener('resize', setHeight);
  }, []);

  React.useEffect(() => {
    function forbidden(e: { keyCode: number; preventDefault: () => void }) {
      if (e.keyCode === 123) {
        e.preventDefault();
      }
    }
    window.oncontextmenu = () => false;
    window.addEventListener('keydown', forbidden);
    return () => window.removeEventListener('keydown', forbidden);
  }, []);

  return (
    <RoutingContext.Provider
      value={{
        setCompany: setCompany,
      }}
    >
      <BrowserRouter>
        <RoutingContainer id="routing-container">
          <RoutingToolbarContainer>
            <Toolbar companyLogoUrl={companyLogoUrl} companySiteUrl={companySiteUrl} />
          </RoutingToolbarContainer>
          <RoutingPageContainer>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/promo/:code" element={<Landing />} />
              <Route path="/promo/:code/leaderboard" element={<Leaderboard />} />
              <Route path="/:id" element={<Testing />} />
              <Route path="/:id/*" element={<UnknownRoute />} />
            </Routes>
          </RoutingPageContainer>
        </RoutingContainer>
      </BrowserRouter>
    </RoutingContext.Provider>
  );
};
