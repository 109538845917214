import styled from 'styled-components';

export const CountdownTimerContainer = styled.div`
  padding: 8px 12px;
  background: ${({ theme }) => theme.colors.backgroundColor};
  box-shadow: ${({ theme }) => theme.shadows.default};
  border-radius: 20px;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  column-gap: 4px;
`;

export const CountdownTimerImg = styled.img`
  width: 24px;
`;
