import styled from 'styled-components';

export const QuestionAnswerContainer = styled.label`
  min-width: 500px;
  max-width: 700px;
  display: flex;
  cursor: pointer;

  p {
    margin-left: 10px;
  }

  @media (max-width: 896px) {
    min-width: 100%;
  }
`;
