import React from 'react';
import { useParams } from 'react-router-dom';
import { TestingViewSelector } from './components';
import { TestingContextProvider } from './context';

export const Testing: React.VFC = () => {
  const id = useParams().id;

  return (
    <TestingContextProvider identifier={id}>
      <TestingViewSelector />
    </TestingContextProvider>
  );
};
