import React, { FormEvent } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, IconField, Input } from 'src/components';
import { BlackHeaderText20, Card } from 'src/styles';
import { Helpers } from 'src/helpers';
import { CompletedTestViewContainer, SendResultForm, TestBody, TestTitle } from './styles';
import { useTestingContext } from '../../context';
import { TestHighlight, TestSignature } from './styles';
import { API, Rating } from 'src/api';

const pluralScore = ['балл', 'балла', 'баллов'];

export const CompletedTestView: React.VFC = () => {
  const [{ commonInfo, identifier }] = useTestingContext();
  const [email, setEmail] = React.useState<string>(commonInfo.candidateEmail ?? '');
  const [formDisabled, setFormDisabled] = React.useState<boolean>(commonInfo.isResultSent ?? false);
  const [rating, setRating] = React.useState<Rating>();

  const onSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    const updated = await API.TestApi.updateEmail(identifier, email);
    const sended = await API.TestApi.sendResult(identifier);

    if (updated && sended) {
      setFormDisabled(true);
    }
  };

  React.useEffect(() => {
    if (commonInfo.promoEvent !== undefined && commonInfo.promoEvent !== null) {
      API.TestApi.getRating(identifier).then(rating => setRating(rating));
    }
  }, [commonInfo]);

  const renderResultSection = () => {
    return rating ? (
      <TestBody>
        Твой результат:
        <IconField
          awesomeIcon={solid('star')}
          value={`${rating.totalPoints} ${Helpers.Utils.plural(rating.totalPoints, pluralScore)}`}
        />
        <IconField awesomeIcon={solid('trophy')} value={`${rating.position} место в рейтинге`} />
        <IconField
          awesomeIcon={solid('stopwatch')}
          value={`${Helpers.DateHelpers.getDateDiffMinutes(commonInfo.completed, commonInfo.started)} мин.`}
        />
      </TestBody>
    ) : (
      <TestBody>
        Твой результат:
        <IconField
          awesomeIcon={solid('star')}
          value={`${commonInfo.gainedScoreValue} ${Helpers.Utils.plural(commonInfo.gainedScoreValue, pluralScore)} из ${
            commonInfo.maxScoreValue
          }`}
        />
        <IconField
          awesomeIcon={solid('stopwatch')}
          value={`${Helpers.DateHelpers.getDateDiffMinutes(commonInfo.completed, commonInfo.started)} мин.`}
        />
      </TestBody>
    );
  };

  return (
    <Card>
      <CompletedTestViewContainer>
        <TestTitle>
          <BlackHeaderText20> {commonInfo.candidateFirstName}, благодарим тебя за прохождение теста!</BlackHeaderText20>
        </TestTitle>
        {renderResultSection()}
        <TestBody>
          {formDisabled
            ? 'Подробный отчет будет отправлен на почту:'
            : 'Ты можешь отправить подробный отчет себе на почту.'}
          <SendResultForm onSubmit={onSubmit}>
            <Input
              id="email"
              placeholder="Email"
              required
              type="email"
              maxLength={255}
              value={email}
              disabled={formDisabled}
              onChange={e => setEmail(e.target.value)}
            ></Input>
            {!formDisabled && (
              <Button type="submit" disabled={formDisabled}>
                Отправить
              </Button>
            )}
          </SendResultForm>
        </TestBody>
        <TestSignature>
          <TestHighlight>{commonInfo.companyName}</TestHighlight>
        </TestSignature>
      </CompletedTestViewContainer>
    </Card>
  );
};
