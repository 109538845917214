import React from 'react';
import logo from 'src/assets/logo.svg';
import { ToolbarContainer, ToolbarLogo, ToolbarOsLogo } from './styles';

type ToolbarProps = {
  companyLogoUrl?: string;
  companySiteUrl?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const Toolbar: React.FC<ToolbarProps> = ({ companyLogoUrl, companySiteUrl }) => {
  return (
    <ToolbarContainer>
      <ToolbarLogo src={logo} />
      {companyLogoUrl && (
        <a href={companySiteUrl} target="_blank" rel="noreferrer">
          <ToolbarOsLogo src={companyLogoUrl} />
        </a>
      )}
    </ToolbarContainer>
  );
};
