import styled from 'styled-components';

export const StartedTestViewContainer = styled.div`
  min-width: 500px;
  max-width: 750px;
  margin: 32px 0;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;

  @media (max-width: 896px) {
    margin: 0;
    min-width: 100%;
  }
`;

export const QuestionHeader = styled.div`
  min-width: 500px;
  max-width: 750px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto auto;
  row-gap: 16px;
  justify-items: center;
  padding-bottom: 64px;

  @media (max-width: 896px) {
    min-width: 100%;
    padding-bottom: 22px;
  }
`;

export const QuestionImage = styled.img`
  margin-top: 24px;
  max-width: 700px;

  @media (max-width: 896px) {
    width: 100%;
  }
`;

export const QuestionAnswers = styled.div`
  min-width: 500px;
  margin-top: 24px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  row-gap: 10px;

  @media (max-width: 896px) {
    min-width: 100%;
  }
`;

export const QuestionButtons = styled.div`
  min-width: 500px;
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;

  @media (max-width: 896px) {
    min-width: 100%;
  }
`;
