import React from 'react';
import { NameColumnContainer } from './styles';

type NameColumnProps = {
  name: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const NameColumn: React.VFC<NameColumnProps> = ({ name }) => {
  return <NameColumnContainer>{name}</NameColumnContainer>;
};
