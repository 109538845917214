import React from 'react';
import odinassLogo from 'src/assets/tech-logo/odinass.svg';
import androidLogo from 'src/assets/tech-logo/android.svg';
import csharpLogo from 'src/assets/tech-logo/csharp.svg';
import cplusplusLogo from 'src/assets/tech-logo/cplusplus.svg';
import devopsLogo from 'src/assets/tech-logo/devops.svg';
import goLogo from 'src/assets/tech-logo/go.svg';
import iosLogo from 'src/assets/tech-logo/ios.svg';
import javaLogo from 'src/assets/tech-logo/java.svg';
import jsLogo from 'src/assets/tech-logo/js.svg';
import phpLogo from 'src/assets/tech-logo/php.svg';
import pmLogo from 'src/assets/tech-logo/pm.svg';
import pythonLogo from 'src/assets/tech-logo/python.svg';
import qaLogo from 'src/assets/tech-logo/qa.svg';
import { TechLogoContainer } from './styles';

type TechLogoProps = {
  type: string;
  onClick: (type: string) => void;
};

export const TechLogo: React.VFC<TechLogoProps> = ({ type, onClick }: TechLogoProps) => {
  switch (type) {
    case '1c':
      return <TechLogoContainer onClick={() => onClick(type)} src={odinassLogo} />;
    case 'android':
      return <TechLogoContainer onClick={() => onClick(type)} src={androidLogo} />;
    case 'c#':
      return <TechLogoContainer onClick={() => onClick(type)} src={csharpLogo} />;
    case 'c++':
      return <TechLogoContainer onClick={() => onClick(type)} src={cplusplusLogo} />;
    case 'devops':
      return <TechLogoContainer onClick={() => onClick(type)} src={devopsLogo} />;
    case 'go':
      return <TechLogoContainer onClick={() => onClick(type)} src={goLogo} />;
    case 'ios':
      return <TechLogoContainer onClick={() => onClick(type)} src={iosLogo} />;
    case 'java':
      return <TechLogoContainer onClick={() => onClick(type)} src={javaLogo} />;
    case 'js':
      return <TechLogoContainer onClick={() => onClick(type)} src={jsLogo} />;
    case 'php':
      return <TechLogoContainer onClick={() => onClick(type)} src={phpLogo} />;
    case 'pm':
      return <TechLogoContainer onClick={() => onClick(type)} src={pmLogo} />;
    case 'python':
      return <TechLogoContainer onClick={() => onClick(type)} src={pythonLogo} />;
    case 'qa':
      return <TechLogoContainer onClick={() => onClick(type)} src={qaLogo} />;
    default:
      return <></>;
  }
};
