import styled from 'styled-components';
import { FlexSpaceBetweenContainer } from 'src/styles';

export const NotStartedTestViewContainer = styled.div`
  min-width: 500px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  row-gap: 20px;

  @media (max-width: 896px) {
    min-width: 100%;
  }
`;

export const TestTitle = styled(FlexSpaceBetweenContainer)`
  min-width: 500px;

  @media (max-width: 896px) {
    min-width: 100%;
  }
`;

export const TestStart = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
`;

export const TestBody = styled.div`
  max-width: 530px;
`;

export const TestParagraph = styled.p`
  margin: 0 0 16px 0;
`;

export const TestSignature = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TestHighlight = styled.span`
  font-weight: 600;
  white-space: nowrap;
`;
