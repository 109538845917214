import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 0;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  width: 0;
  opacity: 0;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  background: ${({ theme, checked }) => (checked ? theme.colors.greenColor : theme.colors.backgroundColor)};
  border-color: ${({ theme, checked }) => (checked ? theme.colors.greenColor : theme.colors.grayColor)};
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  transition: all 150ms;

  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }

  &:active {
    background: ${({ theme }) => theme.colors.greenColorPress};
    border-color: ${({ theme }) => theme.colors.greenColorPress};
  }
`;
