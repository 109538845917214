import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    height: 100vh;
    min-width: 1300px;
    margin: 0;
    font-family: Nunito, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: Nunito, sans-serif;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #9CA5A4;
    border-radius: 6px;
    border: 0 solid transparent;
    background-clip: content-box;

    &:hover {
      background-color: #9CA5A4;
    }
  }

  * {
    box-sizing: border-box;
  }

  @media (max-width: 896px){
    body {
        min-width: inherit;
        height:100%
      }
  }
`;

export const PlainText = styled.p`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.blackColor};
  font-size: 16px;
  padding: 0;
  margin: 0;
`;

export const GrayPlainText = styled(PlainText)`
  color: ${({ theme }) => theme.colors.grayColorFont};
`;

export const NoSelectPlainText = styled(PlainText)`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: pre-line;
`;

export const BlackHeaderText24 = styled.h1`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blackColor};
  font-size: 24px;
  padding: 0;
  margin: 0;

  @media (max-width: 896px) {
    font-size: 18px;
  }
`;

export const BlackHeaderText20 = styled.h1`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blackColor};
  font-size: 20px;
  padding: 0;
  margin: 0;

  @media (max-width: 896px) {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const BlackHeaderText18 = styled.h2`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blackColor};
  font-size: 18px;
  padding: 0;
  margin: 0;
`;

export const BlackHeaderText16 = styled.h3`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blackColor};
  font-size: 16px;
  padding: 0;
  margin: 0;
`;

export const BlackHeaderText14 = styled.h3`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blackColor};
  font-size: 14px;
  padding: 0;
  margin: 0;
`;

export const NoSelectBlackHeaderText16 = styled(BlackHeaderText16)`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: pre-line;
`;

export const NoSelectBlackHeaderText20 = styled(BlackHeaderText20)`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: pre-line;
`;

export const GrayHeaderText18 = styled(BlackHeaderText18)`
  color: ${({ theme }) => theme.colors.grayColorFont};
`;

export const GrayHeaderText16 = styled(BlackHeaderText16)`
  color: ${({ theme }) => theme.colors.grayColorFont};
`;

export const GrayHeaderText14 = styled(BlackHeaderText14)`
  color: ${({ theme }) => theme.colors.grayColorFont};
`;

export const Card = styled.div`
  padding: 24px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  box-shadow: ${({ theme }) => theme.shadows.default};
`;

export const FlexCenterContainer = styled.div`
  display: flex;
  margin: auto;
`;

export const FlexTopCenterContainer = styled(FlexCenterContainer)`
  margin: 64px auto auto auto;

  @media (max-width: 896px) {
    margin: 20px auto auto auto;
    width: 100%;
  }
`;

export const FlexEndContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const FlexSpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Loader = styled.div`
  display: flex;
`;

export const Paragraph = styled.p`
  margin: 0 0 16px 0;
`;

export const PageContainer = styled.div`
  width: 100%;
  margin: 15px 0 15px 0;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  box-shadow: ${({ theme }) => theme.shadows.default};
  @media (max-width: 896px) {
    margin: 15px 0 0 0;
  }
`;
