import styled from 'styled-components';
import { FlexSpaceBetweenContainer } from 'src/styles';

export const CompletedTestViewContainer = styled.div`
  min-width: 500px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  row-gap: 20px;

  @media (max-width: 896px) {
    min-width: 100%;
  }
`;

export const TestTitle = styled(FlexSpaceBetweenContainer)`
  min-width: 500px;

  @media (max-width: 896px) {
    min-width: 100%;
  }
`;

export const TestBody = styled.div`
  max-width: 530px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto auto;
  row-gap: 8px;

  @media (max-width: 896px) {
    min-width: 100%;
  }
`;

export const TestSignature = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TestHighlight = styled.span`
  font-weight: 600;
  white-space: nowrap;
`;

export const SendResultForm = styled.form`
  display: grid;
  grid-template-columns: 8fr auto;
  align-items: center;
  column-gap: 10px;
`;
