import styled from 'styled-components';

export const PositionColumnContainer = styled.div`
  display: grid;
  grid-template-columns: 75px auto;
  width: 150px;
  align-items: center;
`;

export const MedalImg = styled.img`
  height: 30px;
`;
