import React, { ReactNode } from 'react';
import { RequiredWrapperContainer } from './styles';

export type RequiredWrapperProps = {
  children: ReactNode;
} & React.InputHTMLAttributes<HTMLDivElement>;

export const RequiredWrapper: React.FC<RequiredWrapperProps> = ({ children }: RequiredWrapperProps) => {
  return <RequiredWrapperContainer>{children}</RequiredWrapperContainer>;
};
