import React from 'react';
import { RoutingContext } from 'src/pages/Routing';
import { FlexCenterContainer, FlexTopCenterContainer } from 'src/styles';
import { CompletedTestView, NotStartedTestView, StartedTestView } from '..';
import { useTestingContext } from '../../context';

export const TestingViewSelector: React.VFC = () => {
  const [{ commonInfo }] = useTestingContext();
  const { setCompany } = React.useContext(RoutingContext);

  React.useEffect(() => {
    setCompany(commonInfo.companyLogo, commonInfo.companySite);
  }, [commonInfo]);

  if (commonInfo.started && commonInfo.completed) {
    return (
      <FlexCenterContainer>
        <CompletedTestView />
      </FlexCenterContainer>
    );
  } else if (commonInfo.started && !commonInfo.completed) {
    return (
      <FlexTopCenterContainer>
        <StartedTestView />
      </FlexTopCenterContainer>
    );
  } else {
    return (
      <FlexCenterContainer>
        <NotStartedTestView />
      </FlexCenterContainer>
    );
  }
};
