import React, { ReactNode } from 'react';
import { API } from 'src/api';
import { Loader } from 'src/styles';
import { setContext, setNextQuestion } from './actions';
import {
  ActionPayloadType,
  ActionType,
  DispatchType,
  SetContextPayloadType,
  SetNextQuestionPayloadType,
  TestingContextType,
} from './types';

const load = (identifier: string, dispatch: DispatchType): void => {
  API.TestApi.getCommonInformation(identifier).then(res => {
    dispatch(
      setContext({
        identifier: identifier,
        commonInfo: res,
      } as SetContextPayloadType),
    );
  });
};

const getNextQuestion = (oldContext: TestingContextType, dispatch: DispatchType): void => {
  API.TestApi.getNextQuestion(oldContext.identifier).then(res => {
    dispatch(
      setNextQuestion({
        nextQuestion: res,
      } as SetNextQuestionPayloadType),
    );
  });
};

const failQuestion = (oldContext: TestingContextType, dispatch: DispatchType): Promise<boolean> => {
  return API.TestApi.failQuestion(oldContext.identifier).then(res => {
    if (res) {
      getNextQuestion(oldContext, dispatch);
    }
    return res;
  });
};

const skipQuestion = (oldContext: TestingContextType, dispatch: DispatchType): Promise<boolean> => {
  return API.TestApi.skipQuestion(oldContext.identifier).then(res => {
    if (res) {
      getNextQuestion(oldContext, dispatch);
    }
    return res;
  });
};

const answerQuestion = (
  answers: number[],
  oldContext: TestingContextType,
  dispatch: DispatchType,
): Promise<boolean> => {
  return API.TestApi.answerQuestion(oldContext.identifier, answers ?? []).then(res => {
    if (res) {
      getNextQuestion(oldContext, dispatch);
    }
    return res;
  });
};

const completeTest = (oldContext: TestingContextType): Promise<boolean> => {
  return API.TestApi.complete(oldContext.identifier).then(res => {
    return res;
  });
};

const postStat = (eventId: number, oldContext: TestingContextType): Promise<void> =>
  API.TestApi.postStat(oldContext.identifier, eventId);

const reducer = (state: TestingContextType, action: ActionType): TestingContextType => {
  switch (action.type) {
    case 'setContext':
      return {
        ...state,
        identifier: (action as ActionPayloadType<SetContextPayloadType>).payload.identifier,
        commonInfo: (action as ActionPayloadType<SetContextPayloadType>).payload.commonInfo,
      } as TestingContextType;
    case 'setNextQuestion':
      return {
        ...state,
        nextQuestion: (action as ActionPayloadType<SetNextQuestionPayloadType>).payload.nextQuestion,
      } as TestingContextType;
    default:
      return state;
  }
};

const TestingContext = React.createContext<{ state: TestingContextType; dispatch: DispatchType }>(undefined);

type TestingContextProviderProps = {
  identifier: string;
  children: ReactNode;
};

export const TestingContextProvider: React.FC<TestingContextProviderProps> = ({
  identifier,
  children,
}: TestingContextProviderProps) => {
  const [state, dispatch] = React.useReducer(reducer, {
    load: load,
    getNextQuestion: getNextQuestion,
    failQuestion: failQuestion,
    skipQuestion: skipQuestion,
    answerQuestion: answerQuestion,
    completeTest: completeTest,
    postStat: postStat,
  } as TestingContextType);
  const value = { state, dispatch };

  React.useEffect(() => load(identifier, dispatch), []);

  return value.state.identifier ? (
    <TestingContext.Provider value={value}>{children}</TestingContext.Provider>
  ) : (
    <Loader />
  );
};

export const useTestingContext = (): [TestingContextType, DispatchType] => {
  const context = React.useContext(TestingContext);

  return [context.state, context.dispatch];
};
