import React from 'react';
import { API } from 'src/api';
import { Button } from 'src/components';
import { BlackHeaderText20, Card } from 'src/styles';
import { Helpers } from 'src/helpers';
import {
  NotStartedTestViewContainer,
  TestBody,
  TestHighlight,
  TestParagraph,
  TestSignature,
  TestStart,
  TestTitle,
} from './styles';
import { useTestingContext } from '../../context';

const pluralQuestions = ['вопрос', 'вопроса', 'вопросов'];

export const NotStartedTestView: React.VFC = () => {
  const [{ commonInfo, identifier, load }, dispatch] = useTestingContext();

  const start = (): void => {
    API.TestApi.start(identifier).then(res => {
      if (res) {
        load(identifier, dispatch);
      }
    });
  };

  return (
    <Card>
      <NotStartedTestViewContainer>
        <TestTitle>
          <BlackHeaderText20>Привет, {commonInfo.candidateFirstName}!</BlackHeaderText20>
        </TestTitle>
        <TestBody>
          <TestParagraph>
            Компания <TestHighlight>{commonInfo.companyName}</TestHighlight> предлагает пройти тест{' '}
            <TestHighlight>&quot;{commonInfo.title}&quot;</TestHighlight>.
          </TestParagraph>
          <TestParagraph>
            Вас ждет{' '}
            <TestHighlight>
              {commonInfo.totalQuestions} {Helpers.Utils.plural(commonInfo.totalQuestions, pluralQuestions)}{' '}
            </TestHighlight>
            с несколькими вариантами ответов.
            <br /> На них отводится <TestHighlight>{commonInfo.duration} минут</TestHighlight>.
          </TestParagraph>
          <TestSignature>
            <TestHighlight>Желаем удачи, {commonInfo.companyName}</TestHighlight>
          </TestSignature>
        </TestBody>
        <TestStart>
          <Button onClick={start}>Начать тестирование</Button>
        </TestStart>
      </NotStartedTestViewContainer>
    </Card>
  );
};
