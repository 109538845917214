import React from 'react';
import { PositionColumnContainer, MedalImg } from './styles';
import gold from 'src/assets/gold.svg';
import silver from 'src/assets/silver.svg';
import bronze from 'src/assets/bronze.svg';

type PositionColumnProps = {
  position: 'first' | 'second' | 'third' | 'none';
  value: number;
  name: string | null;
} & React.HTMLAttributes<HTMLDivElement>;

export const PositionColumn: React.VFC<PositionColumnProps> = ({ position, value, name }) => {
  const renderMedal = (position: 'first' | 'second' | 'third' | 'none') => {
    switch (position) {
      case 'first':
        return <MedalImg src={gold} />;
      case 'second':
        return <MedalImg src={silver} />;
      case 'third':
        return <MedalImg src={bronze} />;
      default:
        return <MedalImg src={null} />;
    }
  };

  return (
    <PositionColumnContainer>
      {renderMedal(name === null ? 'none' : position)}
      {value}
    </PositionColumnContainer>
  );
};
