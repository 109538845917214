import { DefaultTheme } from 'styled-components';
import { ITheme } from 'src/interfaces/theme';

const lightThemeBase: ITheme = {
  colors: {
    grayColorFont: '#969696',
    backgroundColor: 'white',
    backgroundColorHover: '#e9e9e9',
    backgroundGrayColor: '#f0f1f3',
    blackColor: '#323232',
    grayColor: '#CED8DD',
    grayColorHover: '#95A4AE',
    grayColorPress: '#768692',
    orangeColor: '#ff8743',
    greenColor: '#00BEA5',
    greenColorHover: '#009884',
    greenColorPress: '#008574',
    redColor: '#E02732',
    redColorHover: '#A41920',
    redColorPress: '#750E13',
    ghostColorHover: '#E5F8F6',
    ghostColorPress: '#CCF2ED',
  },
  shadows: {
    default: '0 4px 16px #DFE7EA',
  },
};

export const lightTheme: DefaultTheme = {
  ...lightThemeBase,
};
