import React from 'react';
import { Moment, utc } from 'moment';
import { BlackHeaderText18 } from 'src/styles';
import { CountdownTimerContainer, CountdownTimerImg } from './styles';
import schedule from 'src/assets/schedule.svg';

type CountdownTimerProps = {
  targetDate: Moment;
};

export const CountdownTimer: React.FC<CountdownTimerProps> = ({ targetDate, children }) => {
  const [completed, setCompleted] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>();

  React.useEffect(() => {
    const timer = setInterval(() => tick(timer), 1000);

    return () => clearInterval(timer);
  }, []);

  const tick = (timer: NodeJS.Timeout): void => {
    const diff = targetDate.diff(utc());
    const diffTime = utc(diff);

    setValue(diffTime.format('HH:mm:ss'));

    if (utc() >= targetDate) {
      clearInterval(timer);
      setValue('00:00:00');
      setCompleted(true);
    }
  };

  return completed ? (
    <>{children}</>
  ) : (
    <CountdownTimerContainer>
      <CountdownTimerImg src={schedule}></CountdownTimerImg>
      <BlackHeaderText18>{value}</BlackHeaderText18>
    </CountdownTimerContainer>
  );
};
