import React from 'react';
import { CheckBox } from 'src/components';
import { NoSelectPlainText } from 'src/styles';
import { QuestionAnswerContainer } from './styles';

type QuestionAnswerProps = {
  id: number;
  text: string;
  onChecked?: (id: number, checked: boolean) => void;
};

export const QuestionAnswer: React.VFC<QuestionAnswerProps> = ({ id, text, onChecked }: QuestionAnswerProps) => {
  const [isChecked, setChecked] = React.useState<boolean>(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.target.checked);
    onChecked(id, event.target.checked);
  };

  return (
    <QuestionAnswerContainer>
      <CheckBox checked={isChecked} onChange={onChange} />
      <NoSelectPlainText>{text}</NoSelectPlainText>
    </QuestionAnswerContainer>
  );
};
