import styled from 'styled-components';

export const ErrorContainer = styled.div`
  min-width: 500px;
  max-width: 500px;

  @media (max-width: 896px) {
    min-width: 100%;
  }
`;
