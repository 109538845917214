import { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';
import { CommonInformation, NextQuestion, Rating } from '..';

export class TestApi {
  constructor(private readonly axios: AxiosInstance, private readonly baseUrl: string) {}

  getCommonInformation = (identifier: string): Promise<CommonInformation> =>
    this.axios
      .get(`${this.baseUrl}/api/v1/interviewing/${identifier}/common`)
      .then(res => res.data)
      .catch(err => {
        toast.error(err);
        return Promise.reject(err);
      });

  start = (identifier: string): Promise<boolean> =>
    this.axios
      .post(`${this.baseUrl}/api/v1/interviewing/${identifier}/start`)
      .then(res => res.data)
      .catch(err => {
        toast.error(err);
        return Promise.reject(err);
      });

  complete = (identifier: string): Promise<boolean> =>
    this.axios
      .post(`${this.baseUrl}/api/v1/interviewing/${identifier}/complete`)
      .then(res => res.data)
      .catch(err => {
        toast.error(err);
        return Promise.reject(err);
      });

  getNextQuestion = (identifier: string): Promise<NextQuestion> =>
    this.axios
      .get(`${this.baseUrl}/api/v1/interviewing/${identifier}/nextQuestion`)
      .then(res => res.data)
      .catch(err => {
        toast.error(err);
        return Promise.reject(err);
      });

  skipQuestion = (identifier: string): Promise<boolean> =>
    this.axios
      .post(`${this.baseUrl}/api/v1/interviewing/${identifier}/skipQuestion`)
      .then(res => res.data)
      .catch(err => {
        toast.error(err);
        return Promise.reject(err);
      });

  failQuestion = (identifier: string): Promise<boolean> =>
    this.axios
      .post(`${this.baseUrl}/api/v1/interviewing/${identifier}/failQuestion`)
      .then(res => res.data)
      .catch(err => {
        toast.error(err);
        return Promise.reject(err);
      });

  answerQuestion = (identifier: string, answers: number[]): Promise<boolean> =>
    this.axios
      .post(`${this.baseUrl}/api/v1/interviewing/${identifier}/answerQuestion`, { ids: answers })
      .then(res => res.data)
      .catch(err => {
        toast.error(err);
        return Promise.reject(err);
      });

  postStat = (identifier: string, eventId: number): Promise<void> =>
    this.axios.post(`${this.baseUrl}/api/v1/interviewing/${identifier}/stat/${eventId}`);

  getResult = (identifier: string): void => {
    this.downloadFile(`${this.baseUrl}/api/v1/testResult/${identifier}/pdf`);
  };

  downloadFile = (url: string) => {
    window.open(url);
  };

  sendResult = (identifier: string): Promise<boolean> =>
    this.axios
      .post(`${this.baseUrl}/api/v1/interviewing/${identifier}/send-result`)
      .then(res => res.data)
      .catch(err => {
        toast.error(err);
        return Promise.reject(err);
      });

  updateEmail = (identifier: string, email: string): Promise<boolean> =>
    this.axios
      .put(`${this.baseUrl}/api/v1/interviewing/${identifier}/update-email`, { email })
      .then(res => res.data)
      .catch(err => {
        toast.error(err);
        return Promise.reject(err);
      });

  getRating = (identifier: string): Promise<Rating> =>
    this.axios
      .get(`${this.baseUrl}/api/v1/interviewing/${identifier}/rating`)
      .then(res => res.data)
      .catch(err => {
        toast.error(err);
        return Promise.reject(err);
      });
}
