import React from 'react';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { Button, CountdownTimer } from 'src/components';
import { BlackHeaderText24, Card, GrayPlainText, NoSelectBlackHeaderText20 } from 'src/styles';
import { CompleteTest, Error, QuestionAnswer } from './components';
import { QuestionAnswers, QuestionButtons, QuestionHeader, QuestionImage, StartedTestViewContainer } from './styles';
import { useTestingContext } from '../../context';

const getBrowserVisibilityProp = (): string => {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }
};

const getBrowserDocumentHiddenProp = (): string => {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden';
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msHidden';
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitHidden';
  }
};

const getIsDocumentHidden = (): boolean => {
  return document[getBrowserDocumentHiddenProp()];
};

export const StartedTestView: React.VFC = () => {
  const [context, dispatch] = useTestingContext();
  const { commonInfo, nextQuestion, skipQuestion, answerQuestion, postStat } = context;
  const [checkedAnswers, setCheckedAnswers] = React.useState<number[]>([]);

  React.useEffect(() => context.getNextQuestion(context, dispatch), []);

  React.useEffect(() => {
    const onFocus = () => postStat(0, context);
    const onBlur = () => postStat(1, context);
    const visibilityChange = getBrowserVisibilityProp();
    const onVisibilityChange = () => {
      if (getIsDocumentHidden()) {
        onBlur();
      } else {
        onFocus();
      }
    };

    if (isMobile) {
      document.addEventListener(visibilityChange, onVisibilityChange, false);
    } else {
      window.addEventListener('focus', onFocus);
      window.addEventListener('blur', onBlur);
    }

    return () => {
      if (isMobile) {
        document.removeEventListener(visibilityChange, onVisibilityChange);
      } else {
        window.removeEventListener('focus', onFocus);
        window.removeEventListener('blur', onBlur);
      }
    };
  }, []);

  const checkAnswers = (id: number, checked: boolean): void => {
    if (checked) {
      setCheckedAnswers([...checkedAnswers, id]);
    } else {
      setCheckedAnswers(checkedAnswers.filter(x => x !== id));
    }
  };

  const post = (): void => {
    answerQuestion(checkedAnswers, context, dispatch).then(res => {
      if (res) {
        setCheckedAnswers([]);
      }
    });
  };

  const skip = (): void => {
    skipQuestion(context, dispatch).then(res => {
      if (res) {
        setCheckedAnswers([]);
      }
    });
  };

  if (nextQuestion === undefined) {
    return <Error />;
  } else if (nextQuestion.completed) {
    return <CompleteTest />;
  } else {
    const title = `${commonInfo.title} / ${nextQuestion.categoryName}`;
    const counter = `Вопрос ${commonInfo.totalQuestions - nextQuestion.availableQuestionsCount} из ${
      commonInfo.totalQuestions
    }`;

    return (
      <StartedTestViewContainer>
        <QuestionHeader>
          <BlackHeaderText24>{title}</BlackHeaderText24>
          <GrayPlainText>{counter}</GrayPlainText>
          <CountdownTimer targetDate={moment(nextQuestion.plannedEndDate)}>
            <CompleteTest />
          </CountdownTimer>
        </QuestionHeader>
        <Card>
          <NoSelectBlackHeaderText20>{nextQuestion.questionText}</NoSelectBlackHeaderText20>
          {nextQuestion.questionImageUrl && <QuestionImage src={nextQuestion.questionImageUrl} />}
          <QuestionAnswers>
            {nextQuestion.questionAnswers.map(a => (
              <QuestionAnswer key={a.id} id={a.id} text={a.text} onChecked={checkAnswers} />
            ))}
          </QuestionAnswers>
          <QuestionButtons>
            {nextQuestion.skiped === false && (
              <Button buttonStyle="secondary" onClick={skip}>
                Пропустить
              </Button>
            )}
            <Button onClick={post}>Ответить</Button>
          </QuestionButtons>
        </Card>
      </StartedTestViewContainer>
    );
  }
};
