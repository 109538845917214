import { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';
import { LeaderboardMember, PromoEvent, Test } from './types';

export class LandingApi {
  constructor(private readonly axios: AxiosInstance, private readonly baseUrl: string) {}

  createInterviewing = (
    firstName: string,
    lastName: string,
    email: string,
    contact: string,
    testId: number,
  ): Promise<string> =>
    this.axios
      .post(`${this.baseUrl}/api/v1/landing`, {
        firstName,
        lastName,
        email,
        contact,
        testId,
      })
      .then(res => res.data)
      .catch(err => {
        toast.error(err);
        return Promise.reject(err);
      });

  getTests = (): Promise<Test[]> =>
    this.axios
      .get(`${this.baseUrl}/api/v1/landing`)
      .then(res => res.data)
      .catch(err => {
        toast.error(err);
        return Promise.reject(err);
      });

  aboutPromoEvent = (promoEvent: string): Promise<PromoEvent> =>
    this.axios
      .get(`${this.baseUrl}/api/v1/landing/${promoEvent}/about`)
      .then(res => res.data)
      .catch(err => {
        toast.error(err);
        return Promise.reject(err);
      });

  createPromoEventInterviewing = (
    promoEvent: string,
    firstName: string,
    lastName: string,
    email: string,
    contact: string,
    testId: number,
  ): Promise<string> =>
    this.axios
      .post(`${this.baseUrl}/api/v1/landing/${promoEvent}`, {
        firstName,
        lastName,
        email,
        contact,
        testId,
        promoEvent,
      })
      .then(res => res.data)
      .catch(err => {
        toast.error(err);
        return Promise.reject(err);
      });

  getPromoEventTests = (promoEvent: string): Promise<Test[]> =>
    this.axios
      .get(`${this.baseUrl}/api/v1/landing/${promoEvent}`)
      .then(res => res.data)
      .catch(err => {
        toast.error(err);
        return Promise.reject(err);
      });

  getPromoEventLeaderboard = (promoEvent: string): Promise<LeaderboardMember[]> =>
    this.axios
      .get(`${this.baseUrl}/api/v1/landing/${promoEvent}/leaderboard`)
      .then(res => res.data)
      .catch(err => {
        toast.error(err);
        return Promise.reject(err);
      });

  getPromoEventLeaderboardByParams = (promoEvent: string, params: string): Promise<LeaderboardMember[]> =>
    this.axios
      .get(`${this.baseUrl}/api/v1/landing/${promoEvent}/leaderboard?${params}`)
      .then(res => res.data)
      .catch(err => {
        toast.error(err);
        return Promise.reject(err);
      });
}
