import styled from 'styled-components';

export type InputProps = {
  width?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const StyledInput = styled.input<InputProps>`
  background: transparent;
  border-style: solid;
  border-radius: 8px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.grayColor};
  box-sizing: border-box;
  padding: ${({ awesomeIcon }) => (awesomeIcon ? '14px 16px 14px 45px' : '14px 16px 14px 16px')};
  height: 50px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-family: Nunito, sans-serif;
  transition: all 150ms;

  ::placeholder {
    color: ${({ theme }) => theme.colors.grayColor};
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.greenColorHover};
  }

  &:hover {
    border-color: ${({ theme, disabled }) => (disabled ? theme.colors.grayColor : theme.colors.greenColor)};
  }
`;
