import moment from 'moment';

export class DateHelpers {
  getDateDiffMinutes = (end: Date, start: Date): number => {
    const endDate = moment(end);
    const startDate = moment(start);
    const minutes = endDate.diff(startDate, 'minutes');

    return minutes === 0 ? 1 : minutes;
  };
}
