import axios from 'axios';

const Axios = axios.create();

Axios.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject(error.response?.data?.message);
  },
);

export default Axios;
