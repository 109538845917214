import React from 'react';
import ReactDOM from 'react-dom';
import App from 'src/App';
import reportWebVitals from 'src/reportWebVitals';
import 'src/index.scss';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
