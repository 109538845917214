import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlainText } from 'src/styles';
import { IconFieldContainer } from './styles';

type IconFieldProps = {
  awesomeIcon: IconProp;
  value: string;
};

export const IconField: React.VFC<IconFieldProps> = ({ awesomeIcon, value }: IconFieldProps) => {
  return (
    <IconFieldContainer>
      <FontAwesomeIcon icon={awesomeIcon} />
      <PlainText>{value}</PlainText>
    </IconFieldContainer>
  );
};
