import React from 'react';
import { PlainText } from 'src/styles';
import { useTestingContext } from 'src/pages/Testing/context';
import { CompleteTestContainer } from './styles';

export const CompleteTest: React.VFC = () => {
  const [context, dispatch] = useTestingContext();
  const { identifier, load, completeTest } = context;

  React.useEffect(() => {
    completeTest(context).then(res => {
      if (res) {
        load(identifier, dispatch);
      }
    });
  }, []);

  return (
    <CompleteTestContainer>
      <PlainText>Работа над тестом завершена</PlainText>
    </CompleteTestContainer>
  );
};
