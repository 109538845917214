export class NameHelpers {
  getFullName = (names: string[]): string => {
    const filteredNames = names.filter(n => n);

    switch (filteredNames.length) {
      case 1:
        return filteredNames[0];
      case 2:
        return `${filteredNames[0]} ${filteredNames[1]}`.trim();
      case 3:
        return `${filteredNames[0]} ${filteredNames[1]} ${filteredNames[2]}`.trim();
      default:
        return '';
    }
  };
}
