import React, { FormEvent } from 'react';
import { Body, LandingContainer, Highlight, TestStart, Title, TestStartForm } from './styles';
import { BlackHeaderText20, Card, FlexCenterContainer, PlainText, Paragraph } from 'src/styles';
import { Button, Input, RequiredWrapper, Select } from 'src/components';
import { API } from 'src/api';
import { useParams, useNavigate } from 'react-router-dom';
import { Test } from 'src/api/landing/types';

export const Landing: React.VFC = () => {
  const [firstName, setFirstName] = React.useState<string>('');
  const [lastName, setLastName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [contact, setContact] = React.useState<string>('');
  const [tests, setTests] = React.useState<Test[]>([]);
  const [test, setTest] = React.useState<Test>();
  const navigate = useNavigate();
  const promoEventCode = useParams().code;

  React.useEffect(() => {
    if (promoEventCode) {
      API.LandingApi.aboutPromoEvent(promoEventCode)
        .then(promoEvent => {
          if (promoEvent.isActive) {
            API.LandingApi.getPromoEventTests(promoEvent.promoEventCode).then(tests => setTests(tests));
          } else {
            navigate('/');
          }
        })
        .catch(() => navigate('/'));
    } else {
      API.LandingApi.getTests().then(tests => setTests(tests));
    }
  }, [promoEventCode]);

  const onStartClick = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    const url = promoEventCode
      ? await API.LandingApi.createPromoEventInterviewing(
          promoEventCode,
          firstName,
          lastName,
          email,
          contact,
          test.value,
        )
      : await API.LandingApi.createInterviewing(firstName, lastName, email, contact, test.value);

    navigate('/' + url);
  };

  return (
    <FlexCenterContainer>
      <Card>
        <LandingContainer>
          <Title>
            {firstName === '' ? (
              <BlackHeaderText20>Привет!</BlackHeaderText20>
            ) : (
              <BlackHeaderText20>Привет, {firstName}!</BlackHeaderText20>
            )}
          </Title>
          <Body>
            <Paragraph>
              Компания <Highlight>Open Solutions</Highlight> предлагает пройти тест.
            </Paragraph>
            <PlainText>
              Для начала нам нужно познакомиться. Заполни форму, выбери тест и можешь смело приступать к тестированию.
            </PlainText>
          </Body>
          <TestStartForm onSubmit={onStartClick}>
            <RequiredWrapper>
              <Input
                id="firstName"
                placeholder="Имя"
                required
                type="text"
                maxLength={255}
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              ></Input>
            </RequiredWrapper>
            <RequiredWrapper>
              <Input
                id="lastName"
                placeholder="Фамилия"
                required
                type="text"
                maxLength={255}
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              ></Input>
            </RequiredWrapper>
            <RequiredWrapper>
              <Input
                id="email"
                placeholder="Email"
                required
                type="email"
                maxLength={255}
                value={email}
                onChange={e => setEmail(e.target.value)}
              ></Input>
            </RequiredWrapper>
            <Input
              id="contact"
              placeholder="Телеграм/телефон"
              type="text"
              maxLength={100}
              value={contact}
              onChange={e => setContact(e.target.value)}
            ></Input>
            <RequiredWrapper>
              <Select
                id="test"
                placeholder="Выберите тест"
                required
                classNamePrefix="Select"
                value={test}
                options={tests}
                onChange={e => setTest(e)}
              ></Select>
            </RequiredWrapper>
            <TestStart>
              <Button type="submit">Начать</Button>
            </TestStart>
          </TestStartForm>
        </LandingContainer>
      </Card>
    </FlexCenterContainer>
  );
};
