import styled from 'styled-components';

export const RoutingToolbarContainer = styled.div`
  z-index: 100;
`;

export const RoutingPageContainer = styled.div`
  z-index: 90;
  display: flex;
`;

export const RoutingContainer = styled.div`
  height: 100vh;
  padding: 16px 20px 0 20px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgroundGrayColor};
  height: calc(var(--vh, 1vh) * 100);

  @media (max-width: 896px) {
    padding: 16px;
  }

  ${RoutingToolbarContainer} {
    flex: 0 1 auto;
  }

  ${RoutingPageContainer} {
    flex: 1 1 auto;
    overflow-y: auto;
  }
`;
