import styled from 'styled-components';
import Select from 'react-select';

export const StyledSelect = styled(Select)`
  .Select__control {
    background: transparent;
    border-style: solid;
    border-radius: 8px;
    border-width: 1px;
    border-color: ${({ theme }) => theme.colors.grayColor};
    box-sizing: border-box;
    padding: 0 5px;
    height: 50px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    font-family: Nunito, sans-serif;
    transition: all 150ms;
    cursor: pointer;
  }

  .Select__placeholder {
    color: ${({ theme }) => theme.colors.grayColor};
  }

  .Select__control:hover {
    border-color: ${({ theme, disabled }) => (disabled ? theme.colors.grayColor : theme.colors.greenColor)};
  }

  .Select__control--is-focused {
    box-shadow: 0 0 0 1px transparent;
    outline: none;
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__menu {
    color: #3c3d3e;
  }
`;
