import React from 'react';
import { CheckboxContainer, HiddenCheckbox, Icon, StyledCheckbox } from './styles';

type CheckBoxProps = {
  checked: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const CheckBox: React.VFC<CheckBoxProps> = ({ checked, ...props }: CheckBoxProps) => (
  <CheckboxContainer>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);
