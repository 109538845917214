import Axios from './axios';
import { LandingApi } from './landing';
import { TestApi } from './test';
export * from './test/types';

const isProd = process.env.NODE_ENV === 'production';
const url = process.env.REACT_APP_HOSTNAME;

const getUrl = (): string => {
  if (url && url !== '') {
    return url;
  } else {
    return isProd ? 'https://firstlink.osinit.com' : 'https://firstlink.osinit.com';
  }
};

export const API = {
  TestApi: new TestApi(Axios, getUrl()),
  LandingApi: new LandingApi(Axios, getUrl()),
};
