import { ActionPayloadType, SetContextPayloadType, SetNextQuestionPayloadType } from './types';

export const setContext = (payload: SetContextPayloadType): ActionPayloadType<SetContextPayloadType> => ({
  type: 'setContext',
  payload: payload,
});

export const setNextQuestion = (
  payload: SetNextQuestionPayloadType,
): ActionPayloadType<SetNextQuestionPayloadType> => ({
  type: 'setNextQuestion',
  payload: payload,
});
