import React from 'react';
import { PlainText } from 'src/styles';
import { ErrorContainer } from './styles';

export const Error: React.VFC = () => {
  return (
    <ErrorContainer>
      <PlainText>Работа над тестом невозможна. Повторите попытку или сообщите о проблеме HR</PlainText>
    </ErrorContainer>
  );
};
