import styled from 'styled-components';

export const RequiredWrapperContainer = styled.div`
  display: grid;
  grid-template-columns: auto 0;
  color: red;

  &:after {
    content: '*';
    padding-left: 2px;
    font-size: 20px;
    color: red;
  }
`;
