import styled from 'styled-components';

export type ButtonProps = {
  buttonStyle?: 'primary' | 'secondary' | 'tertiary' | 'ghost' | 'danger';
  minWidth?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

type Color = {
  normal: string;
  hover: string;
  active: string;
};

type ButtonColorScheme = {
  border: Color;
  background: Color;
  font: Color;
};

const buttonStyleMap = (
  theme: any,
  buttonStyle?: 'primary' | 'secondary' | 'tertiary' | 'ghost' | 'danger',
): ButtonColorScheme => {
  switch (buttonStyle) {
    case 'primary':
      return {
        border: {
          normal: theme.colors.greenColor,
          hover: theme.colors.greenColorHover,
          active: theme.colors.greenColorPress,
        } as Color,
        background: {
          normal: theme.colors.greenColor,
          hover: theme.colors.greenColorHover,
          active: theme.colors.greenColorPress,
        } as Color,
        font: {
          normal: theme.colors.backgroundColor,
          hover: theme.colors.backgroundColor,
          active: theme.colors.backgroundColor,
        } as Color,
      } as ButtonColorScheme;
    case 'secondary':
      return {
        border: {
          normal: theme.colors.grayColor,
          hover: theme.colors.grayColorHover,
          active: theme.colors.grayColorPress,
        } as Color,
        background: {
          normal: theme.colors.backgroundColor,
          hover: theme.colors.backgroundColor,
          active: theme.colors.backgroundColor,
        } as Color,
        font: {
          normal: theme.colors.blackColor,
          hover: theme.colors.blackColor,
          active: theme.colors.blackColor,
        } as Color,
      } as ButtonColorScheme;
    case 'tertiary':
      return {
        border: {
          normal: theme.colors.greenColor,
          hover: theme.colors.greenColorHover,
          active: theme.colors.greenColorPress,
        } as Color,
        background: {
          normal: theme.colors.backgroundColor,
          hover: theme.colors.backgroundColor,
          active: theme.colors.backgroundColor,
        } as Color,
        font: {
          normal: theme.colors.greenColor,
          hover: theme.colors.greenColorHover,
          active: theme.colors.greenColorPress,
        } as Color,
      } as ButtonColorScheme;
    case 'ghost':
      return {
        border: {
          normal: theme.colors.backgroundColor,
          hover: theme.colors.ghostColorHover,
          active: theme.colors.ghostColorPress,
        } as Color,
        background: {
          normal: theme.colors.backgroundColor,
          hover: theme.colors.ghostColorHover,
          active: theme.colors.ghostColorPress,
        } as Color,
        font: {
          normal: theme.colors.greenColor,
          hover: theme.colors.greenColor,
          active: theme.colors.greenColor,
        } as Color,
      } as ButtonColorScheme;
    case 'danger':
      return {
        border: {
          normal: theme.colors.redColor,
          hover: theme.colors.redColorHover,
          active: theme.colors.redColorPress,
        } as Color,
        background: {
          normal: theme.colors.redColor,
          hover: theme.colors.redColorHover,
          active: theme.colors.redColorPress,
        } as Color,
        font: {
          normal: theme.colors.backgroundColor,
          hover: theme.colors.backgroundColor,
          active: theme.colors.backgroundColor,
        } as Color,
      } as ButtonColorScheme;
    default:
      return {
        border: {
          normal: theme.colors.greenColor,
          hover: theme.colors.greenColorHover,
          active: theme.colors.greenColorPress,
        } as Color,
        background: {
          normal: theme.colors.greenColor,
          hover: theme.colors.greenColorHover,
          active: theme.colors.greenColorPress,
        } as Color,
        font: {
          normal: theme.colors.backgroundColor,
          hover: theme.colors.backgroundColor,
          active: theme.colors.backgroundColor,
        } as Color,
      } as ButtonColorScheme;
  }
};

export const StyledButton = styled.button<ButtonProps>`
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  border-color: ${({ theme, buttonStyle }) => buttonStyleMap(theme, buttonStyle).border.normal};
  background: ${({ theme, buttonStyle }) => buttonStyleMap(theme, buttonStyle).background.normal};
  color: ${({ theme, buttonStyle }) => buttonStyleMap(theme, buttonStyle).font.normal};
  font-size: 16px;
  font-family: Nunito, sans-serif;
  height: 40px;
  padding: 0 32px;
  cursor: pointer;
  &:hover {
    border-color: ${({ theme, buttonStyle }) => buttonStyleMap(theme, buttonStyle).border.hover};
    background-color: ${({ theme, buttonStyle }) => buttonStyleMap(theme, buttonStyle).background.hover};
    color: ${({ theme, buttonStyle }) => buttonStyleMap(theme, buttonStyle).font.hover};
  }
  &:active {
    border-color: ${({ theme, buttonStyle }) => buttonStyleMap(theme, buttonStyle).border.active};
    background-color: ${({ theme, buttonStyle }) => buttonStyleMap(theme, buttonStyle).background.active};
    color: ${({ theme, buttonStyle }) => buttonStyleMap(theme, buttonStyle).font.active};
  }
  transition: all 150ms;
  min-width: ${({ minWidth }) => minWidth ?? '20px'};
`;
