import styled from 'styled-components';

export const LeaderboardContainer = styled.div`
  padding: 10px 20px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

export const TableContainer = styled.table`
  width: 100%;
  height: 100%;
  border-spacing: 0;
  background-color: transparent;
`;

export const Head = styled.thead`
  background-color: transparent;
`;

export const Body = styled.tbody`
  background-color: transparent;
`;

export const Header = styled.th`
  padding: 8px 4px;
  border-bottom: ${({ theme }) => theme.colors.grayColor};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  text-align: left;
`;

export const Row = styled.tr`
  & th:first-child {
    padding-left: 75px;
  }
`;

export const Cell = styled.td`
  padding: 0 4px;
  border-bottom: ${({ theme }) => theme.colors.grayColor};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  text-align: left;
`;
