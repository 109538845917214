import React from 'react';
import { TechnologyColumnContainer } from './styles';
import { TechLogo } from '../TechLogo';

type TechnologyColumnProps = {
  technologies: string[];
  onClick: (type: string) => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const TechnologyColumn: React.VFC<TechnologyColumnProps> = ({ technologies, onClick }) => {
  return (
    <TechnologyColumnContainer>
      {technologies?.map((tech, index) => (
        <TechLogo key={index} type={tech} onClick={onClick} />
      ))}
    </TechnologyColumnContainer>
  );
};
