import React from 'react';
import { LeaderboardMember } from 'src/api/landing/types';
import { PageContainer } from 'src/styles';
import {
  ColumnDef,
  ColumnHelper,
  Table,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Body, Cell, Head, Header, LeaderboardContainer, Row, TableContainer } from './styles';
import { PositionColumn, NameColumn, TechnologyColumn } from './components';
import { useParams, useSearchParams } from 'react-router-dom';
import { API } from 'src/api';

const RefreshDelay = 300000;
const LeaderboardCapacity = 10;

export const Leaderboard: React.VFC = () => {
  const [data, setLeaders] = React.useState<LeaderboardMember[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const promoEventCode = useParams().code;

  React.useEffect(() => {
    fetchLeaders();
  }, [searchParams]);

  React.useEffect(() => {
    const timer: NodeJS.Timeout = setInterval(() => fetchLeaders(), RefreshDelay);

    return () => clearInterval(timer);
  }, []);

  const fetchLeaders = (): void => {
    if (searchParams.has('date') || searchParams.has('technology')) {
      API.LandingApi.getPromoEventLeaderboardByParams(promoEventCode, searchParams.toString()).then(leaders =>
        setLeaders(formatLeaders(leaders, LeaderboardCapacity)),
      );
    } else {
      API.LandingApi.getPromoEventLeaderboard(promoEventCode).then(leaders =>
        setLeaders(formatLeaders(leaders, LeaderboardCapacity)),
      );
    }
  };

  const formatLeaders = (leaders: LeaderboardMember[], capacity: number): LeaderboardMember[] => {
    if (leaders.length < capacity) {
      const placeholderObjects: LeaderboardMember[] = [];

      for (let i = leaders.length; i < capacity; i++) {
        placeholderObjects.push({
          position: i + 1,
          name: null,
          totalPoints: null,
          completedTests: null,
        } as LeaderboardMember);
      }

      return [...leaders, ...placeholderObjects];
    }

    return leaders;
  };

  const onTechonlogyClick = (type: string): void => {
    if (searchParams.has('technology')) {
      searchParams.set('technology', type);
    } else {
      searchParams.append('technology', type);
    }
    setSearchParams(searchParams);
  };

  const columnHelper: ColumnHelper<LeaderboardMember> = createColumnHelper<LeaderboardMember>();

  const columns: ColumnDef<LeaderboardMember>[] = [
    columnHelper.accessor('position', {
      header: 'Позиция',
      cell: info => {
        switch (info.getValue()) {
          case 1:
            return <PositionColumn position="first" value={info.getValue()} name={info.row.getValue('name')} />;
          case 2:
            return <PositionColumn position="second" value={info.getValue()} name={info.row.getValue('name')} />;
          case 3:
            return <PositionColumn position="third" value={info.getValue()} name={info.row.getValue('name')} />;
          default:
            return <PositionColumn position="none" value={info.getValue()} name={info.row.getValue('name')} />;
        }
      },
    }),
    columnHelper.accessor('name', {
      header: 'Имя участника',
      cell: info => <NameColumn name={info.getValue()} />,
    }),
    columnHelper.accessor('completedTestNames', {
      header: 'Технология',
      cell: info => <TechnologyColumn onClick={tech => onTechonlogyClick(tech)} technologies={info.getValue()} />,
    }),
    columnHelper.accessor('completedTests', {
      header: 'Пройденные тесты',
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('totalPoints', {
      header: 'Набранный балл',
      cell: info => info.getValue(),
    }),
  ];

  const table: Table<LeaderboardMember> = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <PageContainer>
      <LeaderboardContainer>
        <TableContainer>
          <Head>
            {table.getHeaderGroups().map(headerGroup => (
              <Row key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <Header key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </Header>
                ))}
              </Row>
            ))}
          </Head>
          <Body>
            {table.getRowModel().rows.map(row => (
              <Row key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <Cell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Cell>
                ))}
              </Row>
            ))}
          </Body>
        </TableContainer>
      </LeaderboardContainer>
    </PageContainer>
  );
};
