import styled from 'styled-components';
import { FlexSpaceBetweenContainer } from 'src/styles';

export const LandingContainer = styled.div`
  min-width: 500px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  row-gap: 20px;

  @media (max-width: 896px) {
    min-width: 100%;
  }
`;

export const TestStartForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const TestStart = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
`;

export const Title = styled(FlexSpaceBetweenContainer)`
  min-width: 500px;

  @media (max-width: 896px) {
    min-width: 100%;
  }
`;

export const Body = styled.div`
  max-width: 530px;
`;

export const Highlight = styled.span`
  font-weight: 600;
  white-space: nowrap;
`;
